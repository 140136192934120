import React, { ReactElement } from "react";

export default function PaymentAllowedIcon(): ReactElement {
    return (
        <svg
            width="120"
            height="120"
            viewBox="0 0 120 120"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="60" cy="60" r="60" fill="white" />
            <path
                d="M42.2263 64.2544C42.2263 64.9811 41.6468 65.6348 40.8501 65.6348H36.2147C33.3901 65.6348 31 63.31 31 60.4046V47.1111C31 44.278 33.3179 41.8809 36.2147 41.8809H82.7853C85.6099 41.8809 88 44.2057 88 47.1111V60.4046C88 63.2376 85.6821 65.6348 82.7853 65.6348H77.9327C77.2082 65.6348 76.5564 65.0536 76.5564 64.2544C76.5564 63.5278 77.1359 62.8741 77.9327 62.8741H82.7853C84.1615 62.8741 85.2476 61.7847 85.2476 60.4044V47.1109C85.2476 45.7306 84.1614 44.6413 82.7853 44.6413H36.2147C34.8385 44.6413 33.7524 45.7307 33.7524 47.1109V60.4044C33.7524 61.7847 34.8386 62.8741 36.2147 62.8741H40.8501C41.6468 62.8741 42.2263 63.4553 42.2263 64.2544ZM70.1831 86.1197C72.356 86.1197 74.0939 84.3761 74.0939 82.1973V48.8546C74.0939 48.128 73.5144 47.4743 72.7177 47.4743C71.9932 47.4743 71.3414 48.0555 71.3414 48.8546L71.3419 82.1973C71.3419 82.8509 70.8347 83.3596 70.183 83.3596H48.817C48.1653 83.3596 47.6581 82.8509 47.6581 82.1973V48.8546C47.6581 48.128 47.0786 47.4743 46.2818 47.4743C45.4851 47.4743 44.9056 48.0555 44.9056 48.8546V82.1973C44.9056 84.3767 46.644 86.1197 48.8164 86.1197H70.1831ZM65.2578 65.3441C65.9823 65.3441 66.6341 64.7629 66.6341 63.9637V61.9298H68.0825C68.8069 61.9298 69.4587 61.3486 69.4587 60.5494C69.4587 59.8228 68.8792 59.1691 68.0825 59.1691H66.6341C66.6341 56.8443 64.7511 54.9559 62.361 54.9559C60.0431 54.9559 58.0879 56.8444 58.0879 59.2416V61.8567C58.0879 62.7283 57.4362 63.3825 56.5667 63.3825C55.6977 63.3825 55.0455 62.7288 55.0455 61.8567V57.2077C55.0455 56.481 54.466 55.8273 53.6692 55.8273C52.9448 55.8273 52.293 56.4085 52.293 57.2077V59.1691H50.9168C50.1923 59.1691 49.5405 59.7503 49.5405 60.5494C49.5405 61.2761 50.12 61.9298 50.9168 61.9298H52.293C52.293 64.2546 54.176 66.143 56.5661 66.143C58.884 66.143 60.8391 64.2544 60.8391 61.8573V59.2421C60.8391 58.3706 61.4909 57.7164 62.3603 57.7164C63.2293 57.7164 63.8816 58.3701 63.8816 59.2421V63.9636C63.9543 64.6902 64.5338 65.3439 65.2578 65.3439V65.3441Z"
                fill="#384C81"
            />
        </svg>
    );
}

import React, { ReactElement } from "react";

export default function PaymentSecurityIcon(): ReactElement {
    return (
        <svg
            width="120"
            height="120"
            viewBox="0 0 120 120"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="60" cy="60" r="60" fill="white" />
            <path
                d="M37.3739 79.4081L59.2065 90.8136C59.392 90.9114 59.5958 90.9595 59.7997 90.9595C60.0107 90.9595 60.2213 90.9085 60.4126 90.8034L81.3312 79.3975C81.7442 79.1725 82.0005 78.7413 82.0005 78.272V37.7952C82.0005 37.2806 81.6926 36.8161 81.219 36.6152C80.7468 36.4142 80.1985 36.5149 79.8275 36.872C77.7026 38.9222 74.909 40.051 71.9602 40.051C67.0445 40.051 62.7129 36.9148 61.1851 32.2454C60.9153 31.4225 60.2108 30.8886 59.3893 30.8848H59.3806C58.5302 30.8848 57.7631 31.4668 57.4711 32.3361C55.9167 36.9506 51.5982 40.0512 46.7259 40.0512C43.7771 40.0512 40.9839 38.9222 38.8571 36.8721C38.4871 36.5151 37.9397 36.4139 37.4656 36.6153C36.992 36.8162 36.6855 37.2807 36.6855 37.7948V67.6168C36.6855 68.3246 37.2589 68.899 37.9673 68.899C38.6751 68.899 39.249 68.3246 39.249 67.6168L39.248 40.4332C41.464 41.8527 44.0417 42.614 46.7245 42.614C52.19 42.614 57.0873 39.4315 59.3419 34.5554C61.5935 39.4353 66.4782 42.614 71.9592 42.614C74.6425 42.614 77.2197 41.8527 79.4343 40.4332V77.5106L59.7874 88.224L39.2499 77.4951V74.2032C39.2499 73.4954 38.6765 72.9215 37.9681 72.9215C37.2603 72.9215 36.6864 73.4949 36.6864 74.2032V78.2712C36.6869 78.7492 36.951 79.1872 37.3754 79.4074L37.3739 79.4081Z"
                fill="#384C81"
            />
            <path
                d="M73.9034 60.9231C73.9034 52.8929 67.3717 46.3594 59.3421 46.3594C51.3125 46.3594 44.7783 52.8925 44.7783 60.9231C44.7783 68.9537 51.3115 75.4857 59.3421 75.4857C67.3727 75.4857 73.9034 68.953 73.9034 60.9231ZM47.3427 60.9231C47.3427 54.3071 52.7248 48.9237 59.3417 48.9237C65.9586 48.9237 71.3407 54.3072 71.3407 60.9231C71.3407 67.5387 65.9586 72.9221 59.3417 72.9221C52.7248 72.9221 47.3427 67.5386 47.3427 60.9231Z"
                fill="#384C81"
            />
            <path
                d="M66.7539 55.9028C66.2523 55.4022 65.4413 55.4022 64.9408 55.9028L57.6179 63.2251L53.7431 59.3518C53.2429 58.8511 52.4305 58.8511 51.93 59.3518C51.4294 59.8524 51.4298 60.6633 51.93 61.1649L56.7112 65.9456C56.9618 66.1961 57.2889 66.3205 57.618 66.3205C57.9452 66.3205 58.2743 66.1962 58.5248 65.9456L66.755 57.7154C67.2542 57.2148 67.2542 56.4034 66.754 55.9028H66.7539Z"
                fill="#384C81"
            />
        </svg>
    );
}

import React, { ReactElement } from "react";

import colors from "@styles/variables/bestCardProcessingRatesVariables.module.scss";
import CustomerSupportIcon from "../icons/customerSupportIcon";
import TransparentPriceIcon from "../icons/transparentPrice";
import PaymentSecurityIcon from "../icons/paymentSecurity";
import PaymentAllowedIcon from "../icons/paymentAllowed";
import FeatureList from "@components/shared/featureList";

const whyUsList = [
    {
        title: "Transparent Pricing",
        icon: <TransparentPriceIcon />,
    },
    {
        title: "Payment Security",
        icon: <PaymentSecurityIcon />,
    },
    {
        title: "A Variety of Payment Types",
        icon: <PaymentAllowedIcon />,
    },
    {
        title: "Customer Support",
        icon: <CustomerSupportIcon />,
    },
];

export default function WhyUs(): ReactElement {
    return (
        <FeatureList
            featureList={whyUsList}
            mainTitle="What to Look for When Picking a Credit Card Processing Service for Your Business:"
            classes={{
                mainSectionClasses: "max-w-7xl mx-auto block px-4vw lg:px-4",
                mainTitleClasses:
                    "text-xl  lg:text-3xl pb-4 text-center  max-w-4xl mx-auto pt-12 lg:pt-24",
                mainDescriptionClasses:
                    "text-sm  lg:text-xl text-center capitalize max-w-4xl mx-auto pb-12 lg:pb-24",
                innerSectionClasses:
                    "lg:flex pb-4 lg:pb-0 flex-wrap justify-center",
                oneBoxClasses: "text-center mb-12 lg:mb-20 w-full lg:w-1/4",
                iconClasses:
                    "children:mx-auto children:h-[90px] children:w-[90px] lg:children:h-[120px] lg:children:w-[120px] ",
                titleClasses:
                    "mx-auto text-base lg:text-lg mt-6 max-w-[180px] block ",
            }}
            colors={{
                bgColor: colors.whyUsBackgroundColor,

                mainTitleColor: colors.whyUsColor,
                titleColor: colors.whyUsColor,
                mainDescriptionColor: colors.whyUsColor,
            }}
        />
    );
}
